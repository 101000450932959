<template>
  <section class="app-main">
    <el-header class="header">
      <main-header @open-change-password-dialog="openChangePasswordDialog" :menu="menu"></main-header>
    </el-header>
    <transition name="fade" mode="out-in">
      <keep-alive>
        <router-view class="page"></router-view>
      </keep-alive>
    </transition>

    <el-dialog title="修改密码" :visible.sync="dialogFormVisible" width="30%">
      <el-form :rules="rules" ref="dataForm" :model="temp" label-position="left" label-width="100px" style='width: 300px; margin:0 auto;'>
<!--        <el-form-item label="原密码" prop="oldPwd">-->
<!--          <el-input type="password" v-model="temp.oldPwd"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="新密码" prop="pwd">
          <el-input type="password" v-model="temp.pwd"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="repeatPwd">
          <el-input type="password" v-model="temp.repeatPwd"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="changePassword">确认</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script type="text/ecmascript-6">
  import MainHeader from './header'
  import { mapGetters, mapActions } from 'vuex'
  import {apiChangePwd, apiLogout} from '../../api/user'

  export default {
    name: 'MainContent',
    components: {MainHeader},
    data() {
      return {
        menu: this.menuList || JSON.parse(sessionStorage.getItem('menuList') || '[]'),
        dialogFormVisible: false,
        rules: {
          oldPwd: [{required: true, message: '不能为空', trigger: 'blur'}],
          pwd: [{required: true, message: '不能为空', trigger: 'blur'}],
          repeatPwd: [{required: true, message: '不能为空', trigger: 'blur'}]
        },
        temp: {
          oldPwd: '',
          pwd: '',
          repeatPwd: ''
        }
      }
    },
    created() {
    },
    methods: {
      openChangePasswordDialog(val) {
        this.temp = {
          oldPwd: '',
          pwd: '',
          repeatPwd: ''
        }
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
        this.dialogFormVisible = val
      },
      changePassword() {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            if (this.temp.pwd !== this.temp.repeatPwd) {
              this.$message({
                type: 'warning',
                message: '两次密码不一致'
              })
              return
            }
            apiChangePwd(this.temp).then((data) => {
              this.dialogFormVisible = false
              this.$confirm('密码修改成功', '提示', {
                confirmButtonText: '确认',
                showCancelButton: false,
                type: 'warning',
                showClose: false
              }).then(() => {
                apiLogout().then(({data}) => {
                  this.clearAllData({})
                  window.sessionStorage.clear()
                  this.$router.push({name: 'login'})
                })
              })
            })
          }
        })
      },
      ...mapActions([
        'clearAllData'
      ])
    },
    computed: {
      ...mapGetters([
        'menuList'
      ])
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus">
  @import '../../assets/style/variables.styl';

  .app-main
    min-height: 100%;
    height 100%
    max-height 100%
    width: 100%;
    background-color: $color-bg-content;
    .header
      padding 0
    .page
      border-left 1px solid #e7e7e9;
      background-color: $color-bg-content;
      padding: 15px;
      height calc(100% - 90px)
      overflow auto
</style>
