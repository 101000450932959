<template>
  <el-menu :default-active="subMenuActiveName" @select="onSubMenuClick">
    <div class="app-name">肉品追溯管理后台</div>
    <router-link v-for="item in subMenuList" :key="item.id" :to="item.url">
      <el-menu-item :index="item.name" class="menu-item" >
        <i class="el-icon-tickets icon"></i>
        <div class="menu-name">{{item.name}}</div>
      </el-menu-item>
    </router-link>
  </el-menu>
</template>

<script type="text/ecmascript-6">
  import { mapGetters, mapMutations} from 'vuex'
  import {subMenuList} from '../../store/getters'

  export default {
    name: 'MainMenu',
    data() {
      return {}
    },
    created() {
    },
    computed: {
      ...mapGetters([
        'subMenuList',
        'subMenuActiveName'
      ])
    },
    watch: {
      $route: 'routeHandle'
    },
    methods: {
      // 路由操作
      routeHandle (route, from) {
        if (route.path === '/') {} else {
          this.setMenuActiveName(route.meta.menuName)
          this.setSubMenuActiveName(route.meta.title)
        }

        if (route.name === 'main' || route.name === 'index') {
          this.setSubMenuActiveName(null)
        }
      },
      onSubMenuClick(index, indexPath) {
        const subMenu = this.subMenuList.find(item => index === item.name)
        const auth = subMenu.subMenu
        this.setCurrentAuth(auth)
      },
      ...mapMutations({
        setMenuActiveName: 'SET_MENU_ACTIVE_NAME',
        setSubMenuActiveName: 'SET_SUB_MENU_ACTIVE_NAME',
        setCurrentAuth: 'SET_CURRENT_AUTH'
      })
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  @import '../../assets/style/variables.styl';

  .el-menu
    border-right: none;
    .app-name
      width 200px
      height 60px
      line-height: 60px;
      color: #fff;
      font-size: 20px;
      background-color #4994fa

    .menu-item
      height: 50px;
      line-height: 50px;
      width: 200px;
      text-align: left;
      color: #898b8a;
      margin-top 2px
      background-color #f3f7f8
      &.is-active
        background-color: #e6effe;
        color: #4b94fd;
      .menu-name
        display: inline-block;
        width: 80px;
</style>
