<template>
  <div id="header" class="main-header flex flex-ac">
    <el-menu
      class="flex-1 flex flex-ac"
      mode="horizontal"
      text-color="#d4ddfc"
      active-text-color="#fff"
      :default-active="activeMenuName"
      @select="menuClick">
      <el-menu-item v-for="item in menu" :key="item.sort" :index="item.name">{{item.name}}</el-menu-item>
    </el-menu>
    <div class="other flex">
      <span class="charger" @click="handleChangePassword">{{userInfo.name}}</span>
      <div class="exit" @click="logout"></div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
  import {mapMutations, mapGetters, mapActions} from 'vuex'
  import {apiLogout} from '../../api/user'

  export default {
    name: 'MainHeader',
    props: {
      menu: {
        type: Array
      }
    },
    data() {
      return {
        activeMenuName: null,
        userInfo: {}
      }
    },
    created() {
      this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      const openPage = this.$route
      const meta = openPage.meta
      if (meta.isDynamic) {
        this.activeMenuName = meta.menuName
        this.setMenuActiveName(meta.menuName)
        let currentMenu = this.menu.find(item => item.name === meta.menuName)
        this.setSubMenuList(currentMenu.subMenu)
        this.setSubMenuActiveName(meta.title)
      } else {
        this.activeMenuName = this.menu[0].name
        this.setSubMenuList(this.menu[0].subMenu)
        this.setMenuActiveName(this.activeMenuName)
      }
    },
    computed: {
      ...mapGetters([
        'menuActiveName'
      ])
    },
    watch: {
      menuActiveName(val, oldValue) {
        this.activeMenuName = val
        if (val) {
          this.menuClick(val, [val])
        } else {
          this.activeMenuName = this.menu[0].name
        }
      }
    },
    methods: {
      menuClick(index, indexPath) {
        let menuIndex = indexPath[0]
        let select = this.menu.find((item) => {
          return item.name === menuIndex
        })
        const subMenu = select.subMenu
        this.setSubMenuList(subMenu)

        const openPageMeta = this.$route.meta
        if (openPageMeta.isDynamic) {
          this.setMenuActiveName(openPageMeta.menuName)
          this.setSubMenuActiveName(openPageMeta.title)
        } else {
          let selectFirstMenu = subMenu[0]
          if (selectFirstMenu) {
            this.setMenuActiveName(selectFirstMenu.mainName)
            this.setSubMenuActiveName(selectFirstMenu.name)

            this.$router.push({path: selectFirstMenu.url})
          } else {
            this.setMenuActiveName(select.name)
          }
        }
      },
      logout() {
        apiLogout().then(({data}) => {
          this.clearAllData({})
          window.sessionStorage.clear()
          this.$router.push({name: 'login'})
        })
      },
      // 打开更改密码弹框
      handleChangePassword() {
        this.$emit('open-change-password-dialog', true)
      },
      ...mapMutations({
        setSubMenuList: 'SET_SUB_MENU_LIST',
        setMenuActiveName: 'SET_MENU_ACTIVE_NAME',
        setSubMenuActiveName: 'SET_SUB_MENU_ACTIVE_NAME'
      }),
      ...mapActions([
        'clearAllData'
      ])
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus">
  @import '../../assets/style/variables.styl';

  .main-header
    text-align: right;
    font-size: 14px;
    background-color: $color-bg-header;

    .other
      color: $white;
      padding: 0 35px 0 50px;

      .charger
        padding: 1px 20px 0 0;
        border-right: 1px solid #9cb8f7;
        cursor: pointer;

      .exit
        display: inline-block;
        margin-left: 13px;
        width: 20px;
        height: 20px;
        background: url('../../assets/images/exit.png') no-repeat center;
        background-size: 20px;
        cursor: pointer;

    .el-menu
      border: 0;
      margin-left: 5px;
      height 60px
      background-color: $color-bg-header;
      &.el-menu--horizontal
        border-bottom: none;
        >.el-menu-item:not(.is-disabled):hover
          background-color: $color-bg-menu-item-active;
          color #fff !important

      .el-menu-item
        border: 0;
        height: 32px;
        line-height: 32px;
        border-radius: 2px;
        margin-right: 5px;
        cursor: pointer;
        &.is-active
          background-color: $color-bg-menu-item-active !important;
          border: none;
</style>
