/**
 * Created by ZengFanhui on 2020/10/26 10:38
 */
import { doHttp } from '../utils/common'
import {HTTP_TYPE, PAGE_SIZE} from '../utils/constants'

/**
 * 登录
 * @param account
 * @param password
 * @return {*}
 */
export function apiLogin (account, password) {
  let params = {
    account,
    password
  }

  return doHttp('/user/login.action', HTTP_TYPE.GET, params)
}

/**
 * 退出登录
 * @return {*}
 */
export function apiLogout () {
  return doHttp('/user/logout.action', HTTP_TYPE.GET, {})
}

/**
 * 更改密码
 * @param oldPwd
 * @param newPwd
 * @return {*}
 */
export function apiChangePwd (params) {
  return doHttp('/user/changePwd.action', HTTP_TYPE.GET, params)
}

/**
 * 获取用户列表
 * @param type -1:全部
 * @param pageIndex
 * @param pageSize
 * @returns {*}
 */
export function apiGetUserList (query = '', pageIndex = 1, pageSize = PAGE_SIZE) {
  let params = {
    query,
    pageIndex,
    pageSize
  }
  return doHttp('/user/list.action', HTTP_TYPE.GET, params)
}

/**
 * 新增/编辑用户信息
 * @param params
 * @returns {*}
 */
export function apiEditUser(params) {
  return doHttp('/user/edit.action', HTTP_TYPE.POST, params)
}

/**
 * 根据ID删除
 * @param id
 * @returns {*}
 */
export function apiDeleteById(id) {
  let params = {id}
  return doHttp('/user/delete.action', HTTP_TYPE.GET, params)
}

/**
 * 解绑普通微信
 * @param userIds
 * @return {*}
 */
export function apiUnBindUser (userIds) {
  let params = {userIds}

  return doHttp('/user/unBindUser.action', HTTP_TYPE.GET, params)
}
