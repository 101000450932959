<template>
  <el-container class="main-index">
    <el-main class="content">
      <el-aside width="180" class="tree-map">
        <main-menu></main-menu>
      </el-aside>
      <el-main class="right">
        <main-content></main-content>
      </el-main>
    </el-main>
  </el-container>
</template>

<script type="text/ecmascript-6">
  import MainMenu from './menu'
  import MainContent from './content'

  import { apiChangePwd } from '../../api/user'

  export default {
    name: 'MainIndex',
    components: { MainMenu, MainContent },
    data() {
      return {}
    },
    created() {},
    methods: {}
  }
</script>

<style lang="stylus" rel="stylesheet/stylus">
  .main-index
    height 100%
    .content
      padding 0
      display flex
      .tree-map
        height: 100%
        background-color #fff
        position absolute
        overflow auto
        .el-menu
          border-right none
          background-color #fff
      .right
        position relative
        padding 0
        flex 1
        margin-left 200px
</style>
